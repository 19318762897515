<template>
    <BaseFormField
        class="event-waiting-list-button"
        :errors="[errorMessage]"
    >
        <BaseButton
            type="button"
            class="button--secondary"
            :disabled="isSubmitting"
            @click="toggleReservation"
        >
            <template #default>
                <template v-if="isInWaitingList">Afmelden wachtlijst</template>
                <template v-else>Aanmelden wachtlijst</template>
            </template>
        </BaseButton>
    </BaseFormField>
</template>

<script>
import { STATUS } from '~/constants/errorMessages';
import { useEventsStore } from '~/store/events';
import { useToastsStore } from '~/store/toasts';
import { useUserStore } from '~/store/user';

export default {
    props: {
        eventId: {
            type: [String, Number],
            default: null
        },

        eventTitle: {
            type: String,
            default: null
        },
        eventVenueName: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            isSubmitting: false,
            errorMessage: null,
            userStore: useUserStore(),
            eventsStore: useEventsStore(),
        };
    },

    computed: {
        isInWaitingList() {
            return this.userStore.isInWaitingList(this.eventId);
        },

        joinWaitingListConfirmText() {
            if (this.eventTitle) {
                return 'Je meldt je aan voor de wachtlijst van ' +
                    this.eventTitle +
                    '. Dit is de online We Are Public wachtlijst en niet geldig aan de kassa van ' +
                    this.eventVenueName +
                    '.';
            }

            return 'Weet je zeker dat je je wilt aanmelden voor de wachtlijst?';
        },

        leaveWaitingListConfirmText() {
            if (this.eventTitle) {
                return `Weet je zeker dat je je wachtlijst aanmelding voor ${this.eventTitle} wilt annuleren?`;
            }

            return 'Weet je zeker dat je je wachtlijst aanmelding voor dit programma wilt annuleren?';
        }
    },

    methods: {
        toggleReservation() {
            if (this.isInWaitingList) {
                return this.leaveWaitingList();
            }

            return this.joinWaitingList();
        },

        async joinWaitingList() {
            const confirmed = confirm(this.joinWaitingListConfirmText);

            if (!confirmed) {
                return;
            }

            this.isSubmitting = true;

            await useLaravelApi(`/v2.0/user/me/waiting-list/${this.eventId}`, {
                method: 'post',
            }).catch((error) => {
                if (error) {
                    this.errorMessage = STATUS['500'];
                }
                this.isSubmitting = false;
            });

            this.isSubmitting = false;

            this.eventsStore.updateMyListHome();

            // Refresh the user, so we have the most recent reservations.
            this.userStore.fetchUser();

            useToastsStore().addMessage({
                title: 'Je hebt je aangemeld voor de wachtlijst',
                message: '',
                status: 'success'
            });
        },

        async leaveWaitingList() {
            const confirmed = confirm(this.leaveWaitingListConfirmText);

            if (!confirmed) {
                return;
            }

            this.isSubmitting = true;

            await useLaravelApi(`/v2.0/user/me/waiting-list/${this.eventId}`, {
                method: 'DELETE'
            }).catch(() => {
                this.errorMessage = STATUS['500'];
                this.isSubmitting = false;
            });

            // Refresh the user, so we have the most recent reservations.
            await this.userStore.fetchUser();
            this.isSubmitting = false;

            useToastsStore().addMessage({
                title: 'Je hebt je afgemeld voor de wachtlijst',
                message: '',
                status: 'success'
            });

            this.eventsStore.updateMyListHome();
        }
    }
};
</script>

<style src="./EventWaitingListButton.less" lang="less"></style>
