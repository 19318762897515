<template>
    <BasePanel v-if="event">
        <template #title>Haal een ticket aan de deur</template>

        <template #default>
            <!-- eslint-disable vue/no-v-html -->
            <p
                v-if="event.doorsaleInformation"
                v-html="event.doorsaleInformation"
            />
            <!-- eslint-enable vue/no-v-html -->
        </template>
    </BasePanel>
</template>

<script>
export default {
    props: {
        event: {
            type: Object,
            default: null
        }
    }
};
</script>
