<template>
    <div v-if="event">
        <BasePanel v-if="showDoorsaleInformation">
            <template #title>Haal een ticket aan de deur</template>

            <template #default>
                <!-- eslint-disable -->
                <p v-html="event.doorsaleInformation" />
                <!-- eslint-enable -->

                <br>

                <BaseButton
                    class="button button--secondary button--stretch"
                    @click="showDoorsaleInformation = false"
                >
                    Begrepen
                </BaseButton>
            </template>
        </BasePanel>

        <BasePanel v-else-if="event">
            <template #title>Reserveer je ticket in een paar stappen:</template>

            <template #default>
                <!-- eslint-disable vue/no-v-html -->
                <p
                    v-if="event.reservationInformation"
                    v-html="event.reservationInformation"
                />

                <p
                    v-if="event.ticketPickUpInformation"
                    v-html="event.ticketPickUpInformation"
                />
                <!-- eslint-enable vue/no-v-html -->

                <template v-if="ticketPricing.needsPayment && ticketPricing.parsedMoneySaved">
                    <p>
                        Je bespaart €{{ ticketPricing.parsedMoneySaved }} met je We Are Public-pas
                    </p>
                </template>

                <template v-if="event.ticketPartnerUrl">
                    <p style="margin-top: 1.5rem;">
                        <BaseButton
                            :href="event.ticketPartnerUrl"
                            target="_blank"
                            rel="noreferrer noopener"
                            element="a"
                            class="button--secondary"
                        >
                            {{ buttonText }}
                        </BaseButton>
                    </p>
                </template>

                <BaseButton
                    v-if="event.doorsaleEnabled"
                    type="button"
                    class="button button--stretch"
                    style="text-decoration: underline;"
                    @click="showDoorsaleInformation = true"
                >
                    Haal een ticket aan de deur

                    <template #after>
                        <BaseIcon icon="info" />
                    </template>
                </BaseButton>
            </template>
        </BasePanel>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useEventsStore } from '~/store/events';

export default defineComponent({
    props: {
        event: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            showDoorsaleInformation: false,
            eventsStore: useEventsStore()
        };
    },

    computed: {
        ticketPricing() {
            return this.eventsStore.ticketPricing;
        },

        buttonText() {
            if (this.ticketPricing.needsPayment && this.ticketPricing.parsedPrice) {
                return `Reserveer. Incasso €${this.ticketPricing.parsedPrice} na bezoek.`;
            }

            return 'Direct reserveren';
        }
    }
});
</script>
