<template>
    <BaseFormField
        class="event-reservation-button"
        :errors="[errorMessage]"
    >
        <template v-if="confirm">
            <BaseButton
                type="button"
                class="button--secondary"
                :disabled="isSubmitting"
                @click="toggleReservation"
            >
                {{ buttonConfirmationText }}
            </BaseButton>

            <div />

            <BaseButton
                type="button"
                class="button"
                style="margin-top: .5rem;"
                :disabled="isSubmitting"
                @click="$emit('submitted'); confirm = false"
            >
                Annuleren
            </BaseButton>
        </template>

        <BaseButton
            v-if="hasReservation"
            type="button"
            class="button--secondary"
            :disabled="isSubmitting"
            @click="toggleReservation"
        >
            Reservering annuleren
        </BaseButton>

        <BaseButton
            v-else-if="!confirm"
            type="button"
            class="button--secondary"
            :disabled="isSubmitting"
            @click="$emit('needsConfirmation'); confirm = true"
        >
            {{ buttonText }}
        </BaseButton>
    </BaseFormField>
</template>

<script>
import { defineComponent } from 'vue';
import { STATUS, EVENT_FULL_CAPACITY } from '~/constants/errorMessages';
import { useEventsStore } from '~/store/events';
import { useToastsStore } from '~/store/toasts';
import { useUserStore } from '~/store/user';

export default defineComponent({
    props: {
        event: {
            type: Object,
            required: true
        }
    },

    emits: ['submitted', 'needsConfirmation'],

    data() {
        return {
            isSubmitting: false,
            errorMessage: null,
            confirm: false,
            userStore: useUserStore(),
            eventsStore: useEventsStore(),
        };
    },

    computed: {
        hasReservation() {
            return this.userStore.hasReservation(this.event.id);
        },

        makeReservationConfirmText() {
            if (this.eventTitle) {
                return `Weet je zeker dat je een plek wilt reserveren voor ${this.eventTitle}? Reserveer alleen als je ook echt kan gaan. Anders kan iemand anders in jouw plaats.`;
            }

            return 'Weet je zeker dat je een plek wilt reserveren? Reserveer alleen als je ook echt kan gaan. Anders kan iemand anders in jouw plaats.';
        },

        cancelReservationConfirmText() {
            if (this.event.title) {
                return `Weet je zeker dat je je reservering voor ${this.event.title} wilt annuleren?`;
            }

            return 'Weet je zeker dat je je reservering voor dit programma wilt annuleren?';
        },

        ticketPricing() {
            return this.eventsStore.ticketPricing;
        },

        buttonText() {
            if (!this.ticketPricing.needsPayment) {
                return 'Direct reserveren';
            }

            if (this.ticketPricing.parsedPrice) {
                return 'Reserveer direct voor €' + this.ticketPricing.parsedPrice;
            }

            return 'Direct reserveren';
        },

        buttonConfirmationText() {
            if (this.ticketPricing.needsPayment && this.ticketPricing.parsedPrice) {
                return 'Bevestig. Incasso €' + this.ticketPricing.parsedPrice + ' na bezoek.';
            }

            return 'Bevestig reservering';
        }
    },

    methods: {
        toggleReservation() {
            if (this.hasReservation) {
                return this.cancelReservation();
            }

            return this.makeReservation();
        },

        async makeReservation() {
            this.isSubmitting = true;

            await useLaravelApi(`/v2.0/user/me/reservations/${this.event.id}`, {
                method: 'POST'
            }).catch((error) => {
                if (error?.response?.status === 422) {
                    this.errorMessage = EVENT_FULL_CAPACITY;
                } else {
                    this.errorMessage = STATUS['500'];
                }
                this.isSubmitting = false;
            });

            // Refresh the user, so we have the most recent reservations.
            this.eventsStore.updateMyListHome();
            this.userStore.fetchUser();

            this.isSubmitting = false;

            this.$emit('submitted');
            this.confirm = false;
            ++this.eventsStore.needsRefresh;
        },

        async cancelReservation() {
            this.isSubmitting = true;

            await useLaravelApi(`/v2.0/user/me/reservations/${this.event.id}`, {
                method: 'DELETE',
            }).catch(() => {
                this.errorMessage = STATUS['500'];
                this.isSubmitting = false;
            });

            // Refresh the user, so we have the most recent reservations.
            this.userStore.fetchUser();
            this.isSubmitting = false;

            this.$emit('submitted');
            this.confirm = false;

            useToastsStore().addMessage({
                title: 'Je hebt je reservering geannuleerd',
                message: 'Heb je je tóch bedacht? Reserveer dan opnieuw een plekje.',
                status: 'success'
            });

            this.eventsStore.updateMyListHome();
            setTimeout(() => {
                ++this.eventsStore.needsRefresh;
            }, 500);
        }
    }
});
</script>

<style src="./EventReservationButton.less" lang="less"></style>
