<template>
    <BasePanel v-if="showDoorsaleInformation">
        <template #title>Haal een ticket aan de deur</template>

        <template #default>
            <!-- eslint-disable -->
            <p v-if="event.doorsaleInformation" v-html="event.doorsaleInformation" />
            <!-- eslint-enable -->

            <br>

            <BaseButton
                class="button button--secondary button--stretch"
                @click="showDoorsaleInformation = false"
            >
                Begrepen
            </BaseButton>
        </template>
    </BasePanel>

    <BasePanel v-else-if="hasReservation">
        <template #title>Je hebt gereserveerd</template>

        <template #default>
            <!-- eslint-disable -->
            <p v-html="event.ticketPickUpInformation" />
            <!-- eslint-enable -->

            <p>
                Kun je toch niet komen? Annuleer dan alsjeblieft je reservering.
            </p>

            <EventReservationButton
                :event="event"
                @needs-confirmation="needsReservationConfirmation = true"
                @submitted="needsReservationConfirmation = false"
            />
        </template>
    </BasePanel>

    <BasePanel
        v-else-if="
            isFullyBooked
                && event.waitingListEnabled
                && (isInWaitingList || userStore.canMakeReservation)"
    >
        <template
            v-if="isInWaitingList"
            #title
        >
            Je staat op de We Are Public wachtlijst
        </template>

        <template
            v-else
            #title
        >
            Wachtlijst
        </template>

        <template #default>
            <p v-if="isInWaitingList">
                Je krijgt een bericht zodra er een plek vrijkomt.
            </p>

            <p v-else>
                Het maximum aantal reserveringen voor dit event is bereikt.
                Zet jezelf op de wachtlijst en ontvang een bericht wanneer
                je weer online kan reserveren. Dit is een online wachtlijst
                en niet geldig aan de kassa van {{ event.venue.name }}.
            </p>

            <EventWaitingListButton
                :event-id="event.id"
                :event-title="event.title"
                :event-venue-name="event.venue.name"
            />

            <div />

            <BaseButton
                v-if="event.doorsaleEnabled"
                type="button"
                class="button button--stretch"
                style="text-decoration: underline;"
                @click="showDoorsaleInformation = true"
            >
                Of haal een ticket aan de deur

                <template #after>
                    <BaseIcon icon="info" />
                </template>
            </BaseButton>
        </template>
    </BasePanel>

    <BasePanel v-else-if="isFullyBooked">
        <template #default>
            <p>
                Het is helaas niet meer mogelijk om vooraf te reserveren. Als er reserveringen worden geannuleerd komen er weer plekken vrij.
            </p>
        </template>
    </BasePanel>

    <BasePanel v-else-if="!userStore.canMakeReservation">
        <template #title>
            Max aantal reservering bereikt
        </template>

        <template #default>
            <p>
                Helaas, je kunt maximaal {{ maxReservations }} reserveringen tegelijk maken. Zo voorkomen we dat programma’s onnodig vol raken.
                Je kan een andere reservering annuleren en voor dit programma reserveren.
            </p>

            <BaseButton
                class="button button--secondary button--stretch"
                @click="$emit('close')"
            >
                Begrepen
            </BaseButton>
        </template>
    </BasePanel>

    <BasePanel v-else>
        <template
            v-if="needsReservationConfirmation"
            #title
        >
            Weet je zeker dat je een plek wilt reserveren voor {{ event.title }}?
        </template>

        <template
            v-else
            #title
        >
            {{
                !ticketPricing.needsPayment
                    ? 'Reserveer je gratis ticket'
                    : 'Reserveer je ticket'
            }}
        </template>

        <template #default>
            <template v-if="needsReservationConfirmation">
                <p>
                    Reserveer alleen als je ook echt kan gaan. Anders kan iemand anders in jouw plaats.
                </p>
            </template>

            <template v-else>
                <p v-if="!ticketPricing.needsPayment">
                    Reserveer met de knop hieronder. Je ontvangt direct een bevestigingsmail.
                    <template v-if="event.partner">
                        Als er wijzigingen zijn in het programma ontvang je een mail van {{ event.partner }}.
                    </template>
                </p>

                <p v-else-if="ticketPricing.parsedMoneySaved">
                    Je bespaart €{{ ticketPricing.parsedMoneySaved }} met je We Are Public-pas
                </p>
            </template>

            <EventReservationButton
                :event="event"
                @needs-confirmation="needsReservationConfirmation = true"
                @submitted="needsReservationConfirmation = false"
            />
        </template>
    </BasePanel>
</template>

<script setup lang="ts">
import type { EventDate } from '~/@types/data';
import { useEventsStore } from '~/store/events';
import { useGlobalStore } from '~/store/global';
import { useUserStore } from '~/store/user';

const props = defineProps<{
    event: EventDate;
}>();

defineEmits(['close']);

const userStore = useUserStore();
const eventsStore = useEventsStore();
const globalStore = useGlobalStore();

const showDoorsaleInformation = ref(false);
const needsReservationConfirmation = ref(false);

const hasReservation = computed(() => userStore.hasReservation(props.event.id));

const isInWaitingList = computed(() => userStore.isInWaitingList(props.event.id));

const isFullyBooked = computed(() => eventsStore.isFullyBooked);

const ticketPricing = computed(() => eventsStore.ticketPricing);

const maxReservations = globalStore.maxReservations;
</script>
