<template>
    <BaseSheet
        :is-visible="isReservationSheetOpen"
        @close="modalStore.close({ name: MODAL_RESERVATION })"
    >
        <template
            v-if="event"
            #title
        >
            <template v-if="reservationType === RESERVATION_TYPES.WAPRES && hasReservation">
                Mijn reservering
            </template>

            <template v-else>
                Tickets
            </template>
        </template>

        <template #subTitle>
            <EventMeta
                v-if="event"
                class="event-meta--sheet"
                :event="event"
                :show-price="true"
            />
        </template>

        <template
            v-if="event"
            #default
        >
            <EventReservationPanelWapres
                v-if="reservationType === RESERVATION_TYPES.WAPRES"
                :event="event"
                @close="modalStore.close({ name: MODAL_RESERVATION })"
            />

            <EventReservationPanelParres
                v-if="reservationType === RESERVATION_TYPES.PARRES"
                :event="event"
            />

            <EventReservationPanelNores
                v-if="reservationType === RESERVATION_TYPES.NORES"
                :event="event"
            />
        </template>

        <template #footer>
            <template
                v-if="
                    ticketPricing.needsPayment
                        && ticketPricing.parsedPrice
                "
            >
                <p>
                    Je hoeft nu nog niet te betalen.
                    Als je bent geweest incasseren we het bedrag aan het begin van de volgende maand
                    via je lopende automatische incasso
                </p>

                <p>
                    <NuxtLink
                        :to="`/${fairUsePolicy}`"
                        @click.prevent="navigateToFairUsePolicy"
                    >
                        Waarom moet ik €{{ ticketPricing.parsedPrice }} betalen?
                    </NuxtLink>
                </p>
            </template>

            <template
                v-else-if="
                    reservationType === RESERVATION_TYPES.WAPRES
                        && !userStore.canMakeReservation
                "
            >
                <p>
                    <NuxtLink
                        :to="`/${fairUsePolicy}`"
                        @click.prevent="navigateToFairUsePolicy"
                    >
                        Waarom een maximaal aantal reserveringen?
                    </NuxtLink>
                </p>
            </template>
        </template>
    </BaseSheet>
</template>

<script setup lang="ts">
import { MODAL_RESERVATION, useModalStore } from '~/store/modal';
import { useUserStore } from '~/store/user';
import * as RESERVATION_TYPES from '~/constants/reservationTypes.js';
import type { EventDate } from '~/@types/data';
import { useEventsStore } from '~/store/events';

const modalStore = useModalStore();
const userStore = useUserStore();
const eventsStore = useEventsStore();

const event = computed<EventDate>(()=> {
    return modalStore.data[MODAL_RESERVATION];
});

const reservationType = computed(() => {
    return event.value.reservationType;
});

const fairUsePolicy = computed(() => eventsStore.fairUsePolicyUri);

const ticketPricing = computed(() => {
    return eventsStore.ticketPricing;
});

const hasReservation = computed(() => {
    if (!event.value) {
        return;
    }

    return userStore.hasReservation(event.value.id);
});

const isReservationSheetOpen = computed(() => {
    return modalStore.activeModals[MODAL_RESERVATION] === true;
});

const navigateToFairUsePolicy = async() => {
    modalStore.closeAll();

    await navigateTo(`/${fairUsePolicy.value}`);
};
</script>
